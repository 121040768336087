import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'
import ApiTestView from '@/components/ApiTestView.vue'

const TransferServicesView = () =>
  import('@/views/bookings_manager/persons/TransferServicesView.vue')
const PointMapper = () => import('@/views/locations/PointMapper.vue')
const VehiclesTable = () =>
  import('@/views/drivers/vehicles/VehiclesTable.vue')
// const LoginViewVue = () => import('@/views/LoginView.vue')
const NotFoundViewVue = () => import('@/views/NotFoundView.vue')
const DashboardView = () => import('@/views/DashboardView.vue')
const TaxiMapZoom = () => import('@/views/TaxiMapZoom.vue')
const CarsView = () => import('@/views/CarsView.vue')
const AppLayoutVue = () => import('../layout/AppLayout.vue')
const DriversTable = () => import('@/views/drivers/DriversTable.vue')
const BusinessTable = () => import('@/views/business/BusinessTable.vue')
const UserDashboard = () => import('@/views/UserDashboard.vue')
const ImportHoppaView = () => import('@/views/hoppa/ImportHoppaView.vue')
const ImportEntitiesView = () => import('@/views/import_entities/ImportEntitiesView.vue')
const LocationsView = () => import('@/views/locations/LocationsView.vue')
const GaragesView = () => import('@/views/locations/GaragesView.vue')
const PickupPointView = () => import('@/views/locations/PickupPointView.vue')
const MunicipalityView = () => import('@/views/locations/MunicipalityView.vue')
const WorkingAreaView = () => import('@/views/locations/WorkingAreaView.vue')
const QuadrantDayDriverView = () =>
  import('@/views/locations/QuadrantDayDriver.vue')
const PersonView = () =>
  import('@/views/bookings_manager/persons/PersonView.vue')
const PersonFormView = () =>
  import('@/views/bookings_manager/persons/PersonFormView.vue')
import PersonCalendarView from '@/views/bookings_manager/persons/PersonCalendarView.vue'
const PersonCalendarManager = () =>
 import('@/views/bookings_manager/persons/PersonCalendarManager.vue')
const SchedulesView = () =>
  import('@/views/bookings_manager/persons/SchedulesView.vue')
const TrafficNextDay = () => import('@/views/traffic/TrafficNextDay.vue')
const VehiclesView = () =>
  import('@/views/bookings_manager/persons/VehiclesView.vue')
const VehiclesFormView = () =>
  import('@/views/bookings_manager/persons/VehicleFormView.vue')
const VehiclesCalendarView = () =>
  import('@/views/bookings_manager/persons/VehicleCalendarView.vue')
const TourOperatorsView = () =>
  import('@/views/bookings_manager/persons/TourOperatorsView.vue')
const ServiceTypeView = () =>
  import('@/views/bookings_manager/persons/ServiceTypeView.vue')
const FlightArrivalsView = () =>
  import('@/views/bookings_manager/persons/FlightArrivalsView.vue')

const DriverPage = () => import('@/pages/DriverPage.vue')
const ServicesInProgessView = () =>
  import('@/views/driver/services/ServicesInProgress.vue')
const TransferServicesForm = () =>
  import('@/views/bookings_manager/persons/TransferServicesForm.vue')

/**
 * Router configuration
 * @returns router
 */
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layout/AuthLayout.vue'),
      children: [
        {
          path: '',
          name: 'index',
          redirect: () => {
            return { path: '/login' }
          }
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/LoginPage.vue'),
          meta: {
            requireAuth: false
          }
        },
        {
          path: 'logout',
          name: 'logout',
          component: () => import('@/pages/LogoutPage.vue'),
          meta: {
            requireAuth: false
          }
        },
        {
          path: 'forgot-password',
          name: 'forgotPassword',
          component: () => import('@/pages/ForgotPasswordPage.vue'),
          meta: {
            requireAuth: false
          }
        },
        {
          path: 'reset-password',
          name: 'resetPassword',
          component: () => import('@/pages/ResetPasswordPage.vue'),
          meta: {
            requireAuth: false
          }
        },
        {
          path: '/api-test',
          name: 'apiTest',
          component: ApiTestView,
          meta: {
            requireAuth: true
          }
        }
      ]
    },
    {
      path: '/mobile',
      component: () => import('@/layout/MobileLayout.vue'),
      children: [
        {
          path: '',
          name: 'mainMobile',
          component: () => import('@/pages/MobileMainPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'todayService',
          name: 'todayService',
          component: () => import('@/views/mobile/TodayServiceView.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'servicios-en-curso',
          name: 'servicios-en-curso',
          component: ServicesInProgessView,
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'calendar',
          name: 'calendar',
          component: () => import('@/views/mobile/CalendarServiceView.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'servicedetail/:id',
          name: 'servicedetail',
          component: () =>
            import('@/views/driver/services/ServiceDetailView.vue'),
          meta: {
            requireAuth: true
          }
        }
      ]
    },
    {
      path: '/admin',
      component: () => import('@/layout/AdminLayout.vue'),
      children: [
        {
          path: '',
          name: 'mainAdmin',
          component: () => import('@/pages/AdminMainPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'roles',
          name: 'rolesAdmin',
          component: () => import('@/pages/AdminRolesPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'settings',
          name: 'settingsAdmin',
          component: () => import('@/pages/AdminSettingsPage.vue'),
          meta: {
            requireAuth: true
          }
        }
      ]
    },
    {
      path: '/v2/main',
      component: () => import('@/layout/MainLayout.vue'),
      children: [
        {
          path: 'map',
          name: 'mapTaxiZoom',
          component: () => import('@/pages/TaxiMapZoomPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'map/:id',
          name: 'mapZoom',
          component: () => import('@/pages/TaxiMapZoomPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'bookings-services',
          name: 'managerBookingsTransfer',
          component: () => import('@/pages/bookings/TransferServicesPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'bookings-services/new',
          name: 'newBookingTransfer',
          component: () => import('@/pages/bookings/TransferServicesFormPage.vue'),
          meta: {
            requireAuth: true
          }
        },
        {
          path: 'bookings-services/edit/:id',
          name: 'editBookingTransfer',
          component: () => import('@/pages/bookings/TransferServicesFormPage.vue'),
          meta: {
            requireAuth: true
          }
        }
      ]
    },

    {
      path: '/notfound',
      name: 'notFound',
      component: NotFoundViewVue
    },

    {
      path: '/main',
      component: AppLayoutVue,
      children: [
        {
          path: '/',
          name: 'home',
          component: TaxiMapZoom,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/dashboard',
          name: 'dashboard',
          component: DashboardView,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/trafficNextDay',
          name: 'Trafico Diferido',
          component: TrafficNextDay,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/map',
          name: 'map',
          component: TaxiMapZoom,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/map/:id',
          name: 'map_zoom',
          component: TaxiMapZoom,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/vehiculos',
          name: 'vehiculos-anterior',
          component: CarsView,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/drivers',
          name: 'drivers',
          component: DriversTable,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/business',
          name: 'business',
          component: BusinessTable,
          meta: {
            requireAuth: true
          }
        },

        {
          path: '/main/userDashboard',
          name: 'userDashboard',
          component: UserDashboard,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/import',
          name: 'fileImport',
          component: ImportHoppaView,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/import/entities',
          name: 'fileImportEntities',
          component: ImportEntitiesView,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/vehicles',
          name: 'vehicles',
          component: VehiclesTable,
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/main/recursos-humanos',
          name: 'gestorReservas',
          children: [
            {
              path: '/main/recursos-humanos/personas',
              name: 'personas',
              component: PersonView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/recursos-humanos/personas/new',
              name: 'personAdd',
              component: PersonFormView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/recursos-humanos/personas/:id',
              name: 'personEdit',
              component: PersonFormView,
              meta: {
                requireAuth: true
              }
            },
            {
             path: '/main/recursos-humanos/personas-calendar-manager',
             name: 'personasCalendarManager',
             component: PersonCalendarManager,
             meta: {
               requireAuth: true
             }
           },
            {
              path: '/main/gestor-reservas/personas/:id/calendar',
              name: 'employeeCaledar',
              component: PersonCalendarView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/servicios-old',
              name: 'servicios-transfer-old', 
              component: TransferServicesView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/servicios',
              name: 'servicios-transfer',
              redirect: '/v2/main/bookings-services',  
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/servicios/edit/:id',
              name: 'servicios-transfer-edit-form',
              component: TransferServicesForm,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/servicios/new',
              name: 'servicios-transfer-new-form',
              component: TransferServicesForm,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/horarios',
              name: 'horarios',
              component: SchedulesView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/vehiculos',
              name: 'vehiculos',
              component: VehiclesView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/vehiculos/new',
              name: 'vehicleAdd',
              component: VehiclesFormView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/vehiculos/:id',
              name: 'vehicleEdit',
              component: VehiclesFormView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/vehiculos/:id/calendar',
              name: 'vehicleCalendar',
              component: VehiclesCalendarView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/tour-operators',
              name: 'tour-operators',
              component: TourOperatorsView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/service-type',
              name: 'service-type',
              component: ServiceTypeView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/gestor-reservas/flight-arrivals',
              name: 'flight-arrivals',
              component: FlightArrivalsView,
              meta: {
                requireAuth: true
              }
            }
          ]
        },
        {
          path: '/main/locations',
          name: 'mapLocations',
          children: [
            {
              path: '/main/locations/map-locations',
              name: 'localidades',
              component: LocationsView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/locations/garajes',
              name: 'garajes',
              component: GaragesView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/locations/municipios',
              name: 'municipios',
              component: MunicipalityView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/locations/puntos-recogida',
              name: 'puntoRecogida',
              component: PickupPointView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/locations/quadrant-day-driver',
              name: 'quadrantDayDriver',
              component: QuadrantDayDriverView
            },
            {
              path: '/main/locations/area-trabajo',
              name: 'areaTrabajo',
              component: WorkingAreaView,
              meta: {
                requireAuth: true
              }
            },
            {
              path: '/main/locations/validar-puntos',
              name: 'validarPuntos',
              component: PointMapper,
              meta: {
                requireAuth: true
              }
            }
          ]
        }
      ]
    }
  ]
})

// Check if user is authenticated
router.beforeEach(async (to, from, next) => {
  const store = useAuthStore()
  // Set user permissions
  store.updateAbility()
  if (to.meta.requireAuth && !store.isUserAuthenticated()) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
