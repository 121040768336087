export const LOGIN = '/v1/auth/login'
export const LOGOUT = '/v1/auth/logout'
export const FORGOT_PASSWORD = '/v1/auth/forgot-password'
export const RESET_PASSOWRD = '/v1/auth/reset-password'
export const GET_ALL_USERS = '/v1/auth/users'
export const UPDATE_USER = '/v1/auth/users'
export const CREATE_USER = '/v1/auth/users'
export const GET_ALL_ROLES = '/v1/auth/roles'
export const VALIDATE_TOKEN = '/v1/auth/valid-cookie'
export const GET_USER_TIMEZONE = "/v1/auth/user-timezone";
export const SAVE_USER_TIMEZONE = "/v1/auth/save-user-timezone";